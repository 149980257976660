import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Heading } from '@components'

import { DefaultLayout } from '../layouts/defaultlayout'

// eslint-disable-next-line import/no-default-export
export default () => (
  <DefaultLayout isDataLoaded>
    <Helmet>
      <title>Hell-O-Chili - Not Found</title>
    </Helmet>
    <Container fullHeight>
      <Heading title="404 - Diese Seite existiert nicht" subtitle="Öffnen Sie bitte eine andere Seite" />
    </Container>
  </DefaultLayout>
)
